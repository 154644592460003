import { gql } from '@apollo/client';
import { ORDER_DETAIL_FIELDS } from './fragments';

export const MOVE_TO_PREV_STEP = gql`
  mutation moveToPreviousStep {
    moveToPreviousStep {
      state
    }
  }
`;

export const MOVE_TO_NEXT_STEP = gql`
  ${ORDER_DETAIL_FIELDS}
  mutation moveToNextStep {
    moveToNextStep {
      ...OrderDetailFields
    }
  }
`;

export const UPSERT_INVOICE_RECIPIENT = gql`
  mutation upsertInvoiceRecipient(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String!
    $isOwner: Boolean
  ) {
    upsertInvoiceRecipient(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      isOwner: $isOwner
    ) {
      number
      fullName
    }
  }
`;

export const REMOVE_INVOICE_RECIPIENT = gql`
  mutation removeInvoiceRecipient($id: ID!) {
    removeInvoiceRecipient(id: $id) {
      id
    }
  }
`;

export const UPSERT_PAYMENT_METHOD = gql`
  ${ORDER_DETAIL_FIELDS}
  mutation upsertPaymentMethod(
    $id: ID
    $bank: String
    $paymentMode: String!
    $accountName: String
    $accountType: String
    $routingNumber: String
    $accountNumber: String
    $invoiceType: String
    $validateOnly: Boolean
  ) {
    upsertPaymentMethod(
      id: $id
      bank: $bank
      paymentMode: $paymentMode
      accountName: $accountName
      accountType: $accountType
      routingNumber: $routingNumber
      accountNumber: $accountNumber
      invoiceType: $invoiceType
      validateOnly: $validateOnly
    ) {
      order {
        ...OrderDetailFields
      }
      user {
        company {
          defaultPaymentMethod {
            id
            paymentMode
            invoiceType
          }
        }
      }
    }
  }
`;

export const GET_SIGNATURE_URL = gql`
  mutation getSignatureUrl {
    generateAgreementUrl {
      id
      order {
        id
        status
        signatureUrl
      }
    }
  }
`;

export const PREVIEW_DOCUMENT = gql`
  mutation previewDocuments($docId: ID, $docType: String, $filename: String) {
    previewDocuments(docId: $docId, docType: $docType, filename: $filename) {
      encodedDocuments
      number
    }
  }
`;

export const UPDATE_VERIFICATION_DETAILS = gql`
  ${ORDER_DETAIL_FIELDS}
  mutation updateVerificationDetails(
    $firstName: String!
    $lastName: String!
    $title: String!
    $ein: String!
  ) {
    updateVerificationDetails(
      firstName: $firstName
      lastName: $lastName
      title: $title
      ein: $ein
    ) {
      id
      order {
        ...OrderDetailFields
      }
    }
  }
`;

export const UPDATE_USER_INFO = gql`
  mutation updateUserInfo($firstName: String!, $lastName: String!, $title: String!) {
    updateUserInfo(firstName: $firstName, lastName: $lastName, title: $title) {
      id
      order {
        id
        state
      }
    }
  }
`;

export const SAVE_AGREEMENT = gql`
  ${ORDER_DETAIL_FIELDS}
  mutation saveAgreement {
    saveSignedAgreement {
      id
      order {
        ...OrderDetailFields
      }
    }
  }
`;

export const UPDATE_PERSONAL_GUARANTEE = gql`
  ${ORDER_DETAIL_FIELDS}
  mutation updatePersonalGuarantee(
    $agreed: Boolean!
    $street: String!
    $suite: String
    $city: String!
    $state: String!
    $zip: String!
  ) {
    updatePersonalGuarantee(
      agreed: $agreed
      street: $street
      suite: $suite
      city: $city
      state: $state
      zip: $zip
    ) {
      id
      order {
        ...OrderDetailFields
      }
    }
  }
`;

export const UPDATE_FIRST_OPENED_AT = gql`
  mutation updateFirstOpenedAt($currentStep: String!) {
    updateFirstOpenedAt(currentStep: $currentStep) {
      id
      order {
        state
      }
    }
  }
`;

export const FORWARD_APPLICATION = gql`
  mutation forwardApplication(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String!
    $message: String
  ) {
    sendApplication(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      message: $message
    ) {
      id
    }
  }
`;

export const SEND_MESSAGE_TO_VENDOR = gql`
  mutation sendMessageToVendor(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String
    $message: String
  ) {
    sendMessageToVendor(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      message: $message
    ) {
      order {
        id
      }
    }
  }
`;

// Payment Options screen
export const SELECT_ORDER_PROPOSAL = gql`
  mutation selectOrderProposal($proposalNumber: String!) {
    selectOrderProposal(proposalNumber: $proposalNumber) {
      order {
        useVartanaFinancing
        buyerSummary
        reviewDocuments
        currentCheckoutProgress
        currentCheckoutStates
      }
    }
  }
`;

export const UPDATE_INVOICE_RECIPIENTS = gql`
  mutation updateInvoiceRecipients($users: [InputInvoiceRecipient!]!) {
    updateInvoiceRecipients(users: $users) {
      id
      currentCheckoutProgress
      state
    }
  }
`;

export const UPDATE_COMPANY_EIN = gql`
  mutation updateCompanyEin($ein: String!) {
    updateCompanyEin(ein: $ein) {
      verificationStatus
    }
  }
`;

export const VERIFY_COMPANY_EIN = gql`
  mutation verifyCompanyEin {
    verifyCompanyEin {
      verificationStatus
    }
  }
`;
