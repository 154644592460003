import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Form, Formik } from 'formik';
import { useIntercom } from 'react-use-intercom';
import { get, omit } from 'lodash';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { Button } from '@vartana-repo/base-components/buttons';
import { InputField, Checkbox } from '@vartana-repo/base-components/form';
import { Loader } from '@vartana-repo/base-components/loader';
import { verifyEinStatus } from '../../../assets/utils/einVerification';
import { useVerification } from '../../utils/verification.hook';

import {
  useMoveBack,
  VerificationIcon,
  LeftArrow,
  RightArrow,
  GET_SESSION_FOR_USER_INFO,
  UPDATE_VERIFICATION_DETAILS,
  GET_SIGNATURE_URL,
  VERIFY_COMPANY_EIN,
  UPDATE_COMPANY_EIN,
  CrossIcon,
} from '../../../assets';

import PersonaKYC from './PersonaKYC';
import { ForwardApplicationModal } from '../../../components/Modals';
import { mapStateToPath } from '../../routes';

const initialValues = {
  firstName: '',
  lastName: '',
  title: '',
  ein: '', // This is the tax ID
  checkboxChecked: false,
};
const EIN_REGEX = /^\d{2}-\d{7}$/;

const PageHeader = ({
  title,
  subtitle1,
  subtitle2,
  linkAction,
  linkText,
  subTitleLinkAction,
  subTitleLinkText,
  isUserVerified,
}) => (
  <div className="flex flex-col gap-2 pb-4">
    <span className="page-title-small lg:page-title text-vartana-black-100">{title}</span>
    <div className="flex gap-1 items-center body">
      <p>
        {subtitle1}{' '}
        {subTitleLinkText && !isUserVerified && (
          <Button className="inline" variant="linkBlue" onClick={subTitleLinkAction}>
            {`${subTitleLinkText} `}
          </Button>
        )}{' '}
      </p>
    </div>

    <div className="flex flex-col md:flex-row gap-2 body items-start lg:items-center">
      <p>
        {subtitle2}
        {linkText && (
          <Button className="inline" variant="linkBlue" onClick={linkAction}>
            {linkText}
          </Button>
        )}
      </p>
    </div>
  </div>
);

export function Verification() {
  const [isVerified, setIsVerified] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [showPersonalIdentityInfo, setShowPersonalIdentityInfo] = useState(true);
  const [disablePersonaFields, setDisablePersonaFields] = useState(false);

  const urlParams = useParams();
  const navigate = useNavigate();

  const { orderNumber } = urlParams;
  const [{ orderDetail }, updateOrderDetails] = useOutletContext();

  const { data: sessionDetails, loading: sessionDetailsLoading } = useQuery(
    GET_SESSION_FOR_USER_INFO,
    {
      onError: (error) => console.error('[GET_SESSION_FOR_USER_INFO]', error),
    }
  );
  const currentUser = get(sessionDetails, 'session.user');
  const personaBypassed = get(currentUser, 'isPersonaVerificationBypassed', false);

  const {
    einVerificationFailed,
    setEinVerificationFailed,
    showEinLoader,
    setShowEinLoader,
    isEinAlreadyVerified,
    preExistingEinValue,
    disableEIN,
    setDisableEIN,
    einErrorMessage,
    setPersonaVerificationFailed,
    setOfacVerificationFailed,
  } = useVerification(orderDetail, currentUser);

  let validationSchema = Yup.object({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    title: Yup.string().nullable().required('Job title is required'),
    checkboxChecked: Yup.boolean().isTrue(),
  });

  if (disableEIN) {
    validationSchema = validationSchema.shape({
      ein: Yup.string().notRequired(),
    });
  } else {
    validationSchema = validationSchema.shape({
      ein: Yup.string()
        .required('Company tax ID is required')
        .test(
          'Filled',
          'Company tax ID is required',
          (value) => value && EIN_REGEX.test(value)
        ),
    });
  }

  const [backBtnLoading, onBackBtnClick] = useMoveBack(updateOrderDetails);
  const [generateAgreementUrl, { loading: agreementLoading }] = useMutation(GET_SIGNATURE_URL);

  const [updateVerificationDetails, { loading: isLoading }] = useMutation(
    UPDATE_VERIFICATION_DETAILS,
    {
      onError: (error) => console.error('[UPDATE_VERIFICATION_DETAILS]', error),
    }
  );

  const [updateCompanyEIN] = useMutation(UPDATE_COMPANY_EIN);
  const [verifyCompanyEIN] = useMutation(VERIFY_COMPANY_EIN);

  const handleSubmit = useCallback(
    async (values) => {
      const { data, errors } = await updateVerificationDetails({
        variables: { ...omit(values, ['checkboxChecked']) },
      });

      if (errors) console.error('[UPDATE_VERIFICATION_DETAILS] ', errors);
      else if (data) {
        const newOrder = get(data, 'updateVerificationDetails.order');
        if (newOrder) {
          const { data: agreementData, errors: agreementErrors } =
            await generateAgreementUrl();

          if (agreementErrors)
            throw new Error(`[GENERATE_AGREEMENT]: ${JSON.stringify(agreementErrors)}`);

          const signUrl = get(agreementData, 'generateAgreementUrl.order.signatureUrl', '');

          const newRoute = mapStateToPath(orderNumber, newOrder?.state);
          updateOrderDetails(newOrder);
          navigate(newRoute, { state: { signUrl } });
        }
      }
    },
    [
      generateAgreementUrl,
      navigate,
      orderNumber,
      updateOrderDetails,
      updateVerificationDetails,
    ]
  );

  const kycPreferences = get(orderDetail, 'company.kycPreferences', []);
  const companyName = get(orderDetail, 'company.legalName', '');

  const { show: showIntercom } = useIntercom();

  useEffect(() => {
    setIsVerified(get(currentUser, 'verified', false));
  }, [currentUser]);

  useEffect(() => {
    const isPgRequired = get(sessionDetails, 'session.creditAppraisal.pgRequired', false);
    if (isPgRequired) navigate(`/order/${orderNumber}/checkout/pg-verification`);
  }, [sessionDetails, navigate, orderNumber]);

  const pageHeaders = useMemo(() => {
    const headers = {
      title: 'Company and signer verification',
      subtitle1: `${currentUser?.firstName} ${currentUser?.lastName} is the authorized signer for this order.`,
      subtitle2: `See a problem below? `,
      linkText: 'Contact Support',
      linkAction: () => showIntercom(),
      isUserVerified: isVerified,
    };

    if (!isVerified) {
      headers.subTitleLinkAction = () => setShowHelpModal(true);
      headers.subTitleLinkText = 'Forward Order';
      headers.subtitle1 = headers.subtitle1.concat(
        ` Not ${currentUser?.firstName} ${currentUser?.lastName}?`
      );
    }

    return headers;
  }, [currentUser, isVerified, showIntercom]);

  const handleEinInputChange = async (e) => {
    setEinVerificationFailed(false);
    const einValue = e.target.value;

    if (EIN_REGEX.test(einValue)) {
      setShowEinLoader(true);
      const { errors } = await updateCompanyEIN({ variables: { ein: einValue } });
      if (errors) {
        console.error('[updateCompanyEIN] ', errors);
      } else {
        setDisableEIN(true);
        verifyEinStatus(
          verifyCompanyEIN,
          setShowEinLoader,
          setEinVerificationFailed,
          setDisableEIN
        );
      }
    }
  };

  return (
    <Loader
      isLoading={agreementLoading || sessionDetailsLoading}
      className="w-10 h-10 mt-[14rem]"
    >
      <PageHeader
        title={pageHeaders.title}
        subtitle1={pageHeaders.subtitle1}
        subtitle2={pageHeaders.subtitle2}
        icon={pageHeaders.icon}
        linkText={pageHeaders.linkText}
        linkAction={pageHeaders.linkAction}
        subTitleLinkAction={pageHeaders.subTitleLinkAction}
        subTitleLinkText={pageHeaders.subTitleLinkText}
        isUserVerified={pageHeaders.isUserVerified}
      />

      <ForwardApplicationModal
        isOpen={showHelpModal}
        onClose={() => setShowHelpModal(false)}
        updateOrderDetail={updateOrderDetails}
      />

      {currentUser && (
        <Formik
          initialValues={{
            ...initialValues,
            firstName: currentUser?.firstName,
            lastName: currentUser?.lastName,
            title: currentUser?.title,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isValid, dirty, values, errors, touched }) => {
            const submitDisabled =
              !dirty || !isValid || !isVerified || showEinLoader || einVerificationFailed;
            return (
              <Form className="flex flex-col gap-4">
                {showPersonalIdentityInfo && (
                  <div className="flex gap-1 justify-between items-center text-vartana-blue-160 bg-vartana-blue-50 px-4 py-3">
                    <div className="flex">
                      <div className="w-4">
                        <VerificationIcon className="w-4 h-4" />
                      </div>
                      <span className="p-small pl-1">
                        Confirming your company and personal identity is a standard practice
                        against financial fraud
                      </span>
                    </div>
                    <div className="w-2.5">
                      <CrossIcon
                        className="w-2.5 h-2.5 hover:bg-vartana-blue-30 cursor-pointer"
                        onClick={() => {
                          setShowPersonalIdentityInfo(false);
                        }}
                      />
                    </div>
                  </div>
                )}
                {einErrorMessage}

                <div className="flex flex-col gap-4 md:grid md:grid-cols-6">
                  <div className="col-span-6 md:col-span-3">
                    <InputField
                      name="legalEntity"
                      label="Legal entity"
                      errorMsg={errors.lastName}
                      touched={touched.lastName}
                      disabled
                      prefix={companyName}
                    />
                  </div>

                  <div className="col-span-6 md:col-span-3">
                    <InputField
                      name="ein"
                      label="Company tax ID"
                      mask="99-9999999"
                      placeholder={
                        isEinAlreadyVerified && preExistingEinValue ? '' : '__-_______'
                      }
                      errorMsg={
                        errors.ein || (einVerificationFailed ? 'Verification Failed' : '')
                      }
                      touched={touched.ein || einVerificationFailed}
                      onKeyUp={handleEinInputChange}
                      isLoading={showEinLoader}
                      disabled={showEinLoader || disableEIN}
                      disableFullStory
                      prefix={
                        isEinAlreadyVerified && preExistingEinValue ? preExistingEinValue : ''
                      }
                      showVerified={disableEIN}
                    />
                    {showEinLoader && (
                      <div className="text-vartana-gray-140 text-xs mt-2">
                        {isVerified
                          ? 'Validating... please wait'
                          : 'Validating... please proceed to next section'}
                      </div>
                    )}
                  </div>
                </div>

                <PersonaKYC
                  user={currentUser}
                  formValues={values}
                  kycPreferences={kycPreferences}
                  isLoading={sessionDetailsLoading}
                  isTestMode={
                    process.env.NX_NODE_ENV === 'production' &&
                    get(orderDetail, 'company.seller.testMode', false)
                  }
                  errors={errors}
                  touched={touched}
                  setIsVerified={setIsVerified}
                  setPersonaVerificationFailed={setPersonaVerificationFailed}
                  setOfacVerificationFailed={setOfacVerificationFailed}
                  disablePersonaFields={disablePersonaFields}
                  setDisablePersonaFields={setDisablePersonaFields}
                />

                <div className="flex flex-col gap-4 md:grid md:grid-cols-6 pt-2 md:mt-0">
                  <div className="col-span-6 pt-2">
                    <Checkbox
                      name="checkboxChecked"
                      label={
                        <p>
                          I hereby declare that <b>my personal information</b> provided above
                          is true and correct.
                        </p>
                      }
                      disabled={disablePersonaFields && !personaBypassed}
                    />
                  </div>
                </div>

                <div className="flex flex-col justify-end pt-4">
                  <div className="flex justify-between items-center gap-6">
                    <Button
                      variant="linkBlue"
                      isLoading={backBtnLoading}
                      onClick={onBackBtnClick}
                    >
                      <LeftArrow /> Back
                    </Button>

                    <Button type="submit" disabled={submitDisabled} isLoading={isLoading}>
                      Next <RightArrow />
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </Loader>
  );
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle1: PropTypes.string.isRequired,
  subtitle2: PropTypes.string,
  linkText: PropTypes.string,
  linkAction: PropTypes.func,
  subTitleLinkText: PropTypes.string,
  subTitleLinkAction: PropTypes.func,
  isUserVerified: PropTypes.bool,
};

PageHeader.defaultProps = {
  subtitle2: '',
  linkText: '',
  linkAction: undefined,
  subTitleLinkText: PropTypes.string,
  subTitleLinkAction: PropTypes.func,
  isUserVerified: PropTypes.bool,
};
